import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactSection from '../components/home/sectionContact'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      headerBG: file(relativePath: { eq: "page-header-contact.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Contact" keywords={[`design`, `developer`, `react`]} />
      <Header colour="#D0021B" />
      <PageDetailedHeader
        bg={data.headerBG}
        intro="I would love to hear from you, so please feel free to reach out and let's start a conversation."
        hasOverlap
      />
      <ContactSection colour="#D0021B" />
    </Layout>
  )
}

export default ContactPage
